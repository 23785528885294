import React from 'react'
import './about.css'
import ME from '../../assets/About-me.jpg'
// import { FaAward } from "react-icons/fa";
import { LuUsers } from "react-icons/lu";
import { LuFolderKanban } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";


const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />


                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <IoHomeOutline className='about__icon' />
                            <h5>Home</h5>
                            <small>Born Kathmandu, Nepal. </small>
                            {/*Use home icon here */}
                        </article>

                        <article className='about__card'>
                            <LuUsers className='about__icon' />
                            <h5>Favorite Activities</h5>
                            <small>Volleyball/Hiking</small>
                        </article>

                        <article className='about__card'>
                            <LuFolderKanban className='about__icon' />
                            <h5>Academic Intrest</h5>
                            <small>Machine Learning/AI</small>
                        </article>
                    </div>

                    <p>
                        I am a Software Engineer with a B.S. in Computer Science from Purdue University, currently pursuing
                        an M.S. in Computer Science at UMass Amherst, specializing in AI and Security. My professional
                        background encompasses both low-level systems programming and full-stack development, with a growing
                        focus on Machine Learning and AI technologies.

                        My graduate studies are centered on understanding the theoretical foundations of Machine Learning
                        models while continuing to expand my expertise across various domains of Computer Science. I am
                        passionate about leveraging this diverse skill set to develop innovative solutions and tackle
                        complex technical challenges.
                    </p>

                    {/* Change the icons here */}
                    <a href="#experience" className='btn btn-primary'>See My Experiences</a>

                </div>
            </div>
        </section>
    )
}

export default About
