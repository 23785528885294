import React from 'react'
import './service.css'
// import { FaRegCircleCheck } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";

const serviceData = {
    classes: {
        title: "Important Classes",
        items: [
            "Foundations of Computer Science",
            "Programming in C",
            "Data Structures and Algorthims",
            "Computer Architecture",
            "Systems Programming",
            "Intro to Adv. Algorthim Analysis",
            "Operating Systems",
            "Info Systems and Databases",
            "Computer Network and Security",
            "Machine Learning",
            "Advanced NLP"
        ]
    },
    work: {
        title: "Work Experience",
        items: [
            "Machine Learning/FullStack - Pet Quick Start (Startup)",
            "Research Assistant: Cloud Computing",
            "Teaching Assistant - Python Programming",
            "Software Engineer - GE Appliances",
            "Software Developer - KeyByte LLC",
            "Project Manager - Pebblst Project",
            "Software Developer: Merck"
        ]
    },
    misc: {
        title: "Miscellaneous",
        items: [
            "Purdue Men's Club Volleyball",
            "Data Mine Learning Community",
            "Dean's List all 4 years",
            "Jean E. Rubin Scholarship",
            "Purdue Alumni General Scholarship",
            "Boston Herald All-Scholastic in Volleyball"
        ]
    }
};

const Service = () => {
    return (
        <section id='services'>
            <h5>My Knowledge</h5>
            <h2>Classes-Work-Extracurriculars</h2>
            <div className="container services__container">
                {Object.values(serviceData).map((category, index) => (
                    <article className='service' key={index}>
                        <div className="service__head">
                            <h3>{category.title}</h3>
                        </div>
                        <ul className='service__list'>
                            {category.items.map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <div className="icon-text-wrapper">
                                        <FaRegCircleCheck className='service__list-icon' />
                                        <p>{item}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </article>
                ))}
            </div>
        </section>
    )
}

export default Service