import React from 'react'
import './experience.css'
import { FaCheckCircle } from "react-icons/fa";

const experienceData = {
    languages: {
        title: "Most Used Languages Over The Years",
        skills: [
            {
                name: "Python",
                experience: "4+ years"
            },
            {
                name: "Java",
                experience: "4+ years"
            },
            {
                name: "C Programming",
                experience: "3+ years"
            },
            {
                name: "C++",
                experience: "2+ year"
            },
            {
                name: "JavaScript",
                experience: "2+ year"
            },
            {
                name: "Dart",
                experience: "1+ year"
            }
        ]
    },
    tools: {
        title: "Most Used Tools/Frameworks Over The Years",
        skills: [
            {
                name: "ReactJs",
                experience: "2+ years"
            },
            {
                name: "NodeJs",
                experience: "2+ years"
            },
            {
                name: "Django",
                experience: "2+ years"
            },
            {
                name: "Flutter",
                experience: "1+ years"
            },
            {
                name: "SQL/NoSQL",
                experience: "1+ years"
            },
            {
                name: "Android Studio",
                experience: "1+ years"
            }
        ]
    }
};

const Experience = () => {
    return (
        <section id='experience'>
            <h5>What skills I have</h5>
            <h2>My Experience</h2>

            <div className="container experience__container">
                {Object.entries(experienceData).map(([key, category]) => (
                    <div key={key} className={`experience__${key}`}>
                        <h3>{category.title}</h3>
                        <div className="experience__content">
                            {category.skills.map((skill, index) => (
                                <article className='experience__details' key={index}>
                                    <FaCheckCircle className='experience__details-icon' />
                                    <div className='experience__text'>
                                        <h4>{skill.name}</h4>
                                        <small className='text-light'>{skill.experience}</small>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Experience
